import auth from '@/services/CRMinitAuth';

const list = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}exam_slots/`, {
    params: query
  });
};

const getLastReloadTime = () => {
  return auth.get(`${process.env.VUE_APP_CRM_API}exam_slots/get_last_reload_time/`);
};

const reloadTopSlots = data => 
  auth.post(`${process.env.VUE_APP_CRM_API}exam_slots/reload_top_slots/`, data);

const reloadMijnSlots = data => 
  auth.post(`${process.env.VUE_APP_CRM_API}exam_slots/reload_mijn_slots/`, data);

export default {
  list,
  getLastReloadTime,
  reloadTopSlots,
  reloadMijnSlots
}