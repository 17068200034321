import api from '@/services/api/api-slots';

export default class SlotsService {
  async list(query) {
    try {
      let res = await api.list(query);
      if(!res.results) throw new Error('Something wrong and slots not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        }
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLastReloadTime() {
    try {
      let res = await api.getLastReloadTime();
      let data = {
        top: res.top_last_reload,
        mijn: res.mijn_last_reload
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async reloadTOP() {
    try {
      let res = await api.reloadTopSlots();
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async reloadMIJN() {
    try {
      let res = await api.reloadMijnSlots();
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}