<script>
import SlotsModel from "@/app/admin/modules/slots/core/slots-model"
import Svc from "@/app/admin/modules/slots/core/slots-service"
import errorsMixin from '@/mixins/errors.mixin'
import PaginationModel from "@/models/paginationModel"

export default {
  
  render() {
    return this.$scopedSlots.default({
      model: this.model,
      loadList: this.loadList,
      loadingErr: this.loadingErr,
      loading: this.loading
    })
  },

  mixins: [errorsMixin],

  data:() => ({
    model: new SlotsModel(),
    loadingErr: null,
    pagination: null,
    loading: false,
    svc: new Svc()
  }),

  methods: {

    async loadList({more = false, query = {}}) {
      try {
        this.loading = true
        this.loadingErr = null
        let res = await this.svc.list(query)
        if(!more) {
          this.model.list = {}
          if(!Object.keys(res.results).length) this.loadingErr = 'According to your parameters, there are no free slots. Try another date, time, location or day.'
        } 
        this.pagination = new PaginationModel({page: 1, size: 500})
        this.model.setList(res.results, this.$set)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },
  }
}
</script>