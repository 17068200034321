import { SLOT_ARG } from '../slots-model';
import { convertToDefaultHourFormat } from '@/util';

export default class SlotItemModel {
  constructor(item = {}) {
    this.location = item[SLOT_ARG.LOCATION];
    this.datetime = item[SLOT_ARG.DATETIME];
    this.quantity = item[SLOT_ARG.QUANTITY];
    this.type = item[SLOT_ARG.TYPE];
    this.date = item.date;
  }

  get timeHour() {
    return convertToDefaultHourFormat(this.datetime);
  }
}
