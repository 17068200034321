import { convertToDefaultDateFormat } from '@/util';
import SlotItemModel from './models/slotItemModel';

export const SLOT_ARG = {
  LOCATION: 'location',
  DATETIME: 'datetime',
  QUANTITY: 'quantity',
  TYPE: 'type'
};

export default class SlotsModel {
  constructor() {
    this.list = {};
    this.$set = null;
  }

  setList(payload, set) {
    this.$set = set;
    payload.forEach(slot => {
      let date = convertToDefaultDateFormat(slot[SLOT_ARG.DATETIME]);
      slot.date = date;
      if (date in this.list) {
        this.setToLocation(slot, date);
      } else {
        this.setToDate(slot, date);
      }
    });
  }

  setToLocation(slot, date) {
    if (slot[SLOT_ARG.LOCATION] in this.list[date])
      this.list[date][slot[SLOT_ARG.LOCATION]].push(new SlotItemModel(slot));
    else this.$set(this.list[date], [slot[SLOT_ARG.LOCATION]], [new SlotItemModel(slot)]);
  }

  setToDate(slot, date) {
    let item = {
      [slot[SLOT_ARG.LOCATION]]: [new SlotItemModel(slot)]
    };
    this.$set(this.list, date, item);
  }
}